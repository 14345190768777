import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker v7 with your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_Camera_Control/",
  "dateChanged": "2022-08-10",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 7 of ioBroker. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker with your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of ioBroker. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_Camera_Control/' locationFR='/fr/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_Camera_Control/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "iobroker-with-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-with-your-wqhd-instar-mqttv5-broker",
        "aria-label": "iobroker with your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker with your WQHD INSTAR MQTTv5 Broker`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my IN-9408 2k+ with the new Version 7 of ioBroker. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by installing the `}<strong parentName="p">{`MQTT-Client`}</strong>{` Adapter (you do not need the `}<em parentName="p">{`MQTT Broker/Client`}</em>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/72fb1ea362d25f2c13816807c77b3f55/40601/ioBrokerv7_INSTAR_MQTTv5_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB3ElEQVQoz0VQzWsTQRzdgyBRid11Z3dnduY3H7s7M7vdNMkGEkQriIeKYFVoi/loghVpSimNMVIrrXrQU/HmwZMH/xBP/mEaofgODx6PB+89B2HwEcIkbrfb3V5v/c56s9fobtx7trUrpdDaGGPsJYyxS3nJTui77rUr7vWrRV4URbHWWDOlbnSaVadrrW02m3meCyGklErKJE1VZlSSqjRTKnHu7n2sRuf64YtGtWpKY3ObpbpqVVKKsiwbZamUEkJyLrI0VboQtsml4okGIZ3Nr78eXPy+/fpHsWpanVaSSilETCllNKYsphw4B844ByEEMEaXlgAAzrmjsyT0PW+lzhirWtXFl++LxclsdjyfvzldHH443X++82R+eP7u5OzgYH82m5++Pfr0fjre7TMAx1pLMPZ9FNM4t3Y6OZ5M9kaj0XgyGQ+2hp9/3n+8M9wcvno5HQz6/cFw++mjwdm3jdERkNAxWiulgiAAAEppahJQTCqVLKeKFURuurfSLOEpqEQiFNRqtRsucv2AAzg6UWEUeZ4HAIyBzS3POQiGEKrX6xhHhGCdaVUqJhmOsOd5QYDimMCydp5H/8MMYwwMaEyjKEIIRVFECIkwhn8H4uVAPwxDQsjfw/4A1xBhXBr9R4IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72fb1ea362d25f2c13816807c77b3f55/e4706/ioBrokerv7_INSTAR_MQTTv5_01.avif 230w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/d1af7/ioBrokerv7_INSTAR_MQTTv5_01.avif 460w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/7f308/ioBrokerv7_INSTAR_MQTTv5_01.avif 920w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/59707/ioBrokerv7_INSTAR_MQTTv5_01.avif 945w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/72fb1ea362d25f2c13816807c77b3f55/a0b58/ioBrokerv7_INSTAR_MQTTv5_01.webp 230w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/bc10c/ioBrokerv7_INSTAR_MQTTv5_01.webp 460w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/966d8/ioBrokerv7_INSTAR_MQTTv5_01.webp 920w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/a70f5/ioBrokerv7_INSTAR_MQTTv5_01.webp 945w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/72fb1ea362d25f2c13816807c77b3f55/81c8e/ioBrokerv7_INSTAR_MQTTv5_01.png 230w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/08a84/ioBrokerv7_INSTAR_MQTTv5_01.png 460w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/c0255/ioBrokerv7_INSTAR_MQTTv5_01.png 920w", "/en/static/72fb1ea362d25f2c13816807c77b3f55/40601/ioBrokerv7_INSTAR_MQTTv5_01.png 945w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/72fb1ea362d25f2c13816807c77b3f55/c0255/ioBrokerv7_INSTAR_MQTTv5_01.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now configure your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`camera MQTT broker`}</a>{` to operate in `}<strong parentName="p">{`Broker Mode`}</strong>{` - so it becomes the primary MQTT broker in your network:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/274e62c121b1c7f0535c4a54665604f2/024d6/ioBrokerv7_INSTAR_MQTTv5_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACO0lEQVQoz2XRS1PTUBiA4W4dBbR1hg3NjTRtkpMEWeAwU9s0TZuQFpqcW25NrDrMsBBH2CBeFrp367gCARf+TCcNFyvvfItsnvlyzqlomgaAprSaG1svvONz5/iyd3h2f8y3Z+7788nJb/T6SBL5tXqdZdkKAEBVQaspPdtqRx9/hZ+uyOkFOb38b/CHi+Tz1ezbH9OL11ZXmTonilJFuUnXANx1I7QLd52JN5h4tu8NgvGwmNEwGA38vVFAYllsVJdqAi+2WmpFnSfLsmFsBBAhTMIoTqdZluVRnECECQ1pGBFCA4gRpkDRass1nhEkqXWHdV0PgoAQkuf5bDbL8zzLMkIIhBAjBCEkhKRposqgtlTjGEFqLGJKaZqmYVQUBIHv+xhjSima4zAMp9NMldXqoyrL8A2xuYAxxlEUUUoxxhBChJDneaZpdua12+3hwAYKePzwCccIgiAtYEJIksSlL/F4PLZtu19kWfbQCyJVBssPVpg6J6w3y6da2HyLS4/nIQTjJH21f9BYb1ZXnoqS0pD1iizL/+IkiW9lUVBUfiAc0um+rhssx4sNSVgXCwwAKN55juMoLq/n7sw9q2v2zG632+k63t7m5ibDMALP8Tx3vVlRFMMwEEKUhrdbEUKjnWG/s211ttt967nV6++411gQeJ6/+23DMEpwc06Cgwl5cxyf/IyOvn/1yY89+MVHG/exoiiapjmO47quc53rDG1nErvJOyc8mPUHh6b10h4AXWdZtqA8/xfoKN7/1CdHaAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/274e62c121b1c7f0535c4a54665604f2/e4706/ioBrokerv7_INSTAR_MQTTv5_02.avif 230w", "/en/static/274e62c121b1c7f0535c4a54665604f2/d1af7/ioBrokerv7_INSTAR_MQTTv5_02.avif 460w", "/en/static/274e62c121b1c7f0535c4a54665604f2/7f308/ioBrokerv7_INSTAR_MQTTv5_02.avif 920w", "/en/static/274e62c121b1c7f0535c4a54665604f2/7733b/ioBrokerv7_INSTAR_MQTTv5_02.avif 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/274e62c121b1c7f0535c4a54665604f2/a0b58/ioBrokerv7_INSTAR_MQTTv5_02.webp 230w", "/en/static/274e62c121b1c7f0535c4a54665604f2/bc10c/ioBrokerv7_INSTAR_MQTTv5_02.webp 460w", "/en/static/274e62c121b1c7f0535c4a54665604f2/966d8/ioBrokerv7_INSTAR_MQTTv5_02.webp 920w", "/en/static/274e62c121b1c7f0535c4a54665604f2/85db8/ioBrokerv7_INSTAR_MQTTv5_02.webp 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/274e62c121b1c7f0535c4a54665604f2/81c8e/ioBrokerv7_INSTAR_MQTTv5_02.png 230w", "/en/static/274e62c121b1c7f0535c4a54665604f2/08a84/ioBrokerv7_INSTAR_MQTTv5_02.png 460w", "/en/static/274e62c121b1c7f0535c4a54665604f2/c0255/ioBrokerv7_INSTAR_MQTTv5_02.png 920w", "/en/static/274e62c121b1c7f0535c4a54665604f2/024d6/ioBrokerv7_INSTAR_MQTTv5_02.png 961w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/274e62c121b1c7f0535c4a54665604f2/c0255/ioBrokerv7_INSTAR_MQTTv5_02.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can configure the ioBroker `}<strong parentName="p">{`MQTT Client`}</strong>{` Adapter with the IP address of the Broker camera, the Broker port and login. Don't forget to set the `}<strong parentName="p">{`MQTT Version`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5`}</code>{`. Optionally, you can define a topic the client should use to announce that it connected or is about to disconnect - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iobroker/state`}</code>{` with the values `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alive`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dead`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/09e48/ioBrokerv7_INSTAR_MQTTv5_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACBUlEQVQoz22SwYvUMBTG5+BFEJl2bGd2ZpK8vLwkTZqmnd3Z2c6CKCsoIl5WELwIe/EieFCv3gXxL1LwX9OZrB3c9Xd6tP36fV/yRtoYApbdvfNgfP+s3263/Waz2Zyuj09Ozvq+iZH0fzDGSClHj66+9K8/uBfvcP3k/dvPff9wdjQVAjjnbD5jswlfHAkB4l8AYLlcji6//3z57cfjr7/Cq48eSZHmnAsAtly48+fu8pPcPBVscUN/LeZsgXxRju8V+Zi0MsZYa421rrLd+UX77E3YXmhSjN9kJz7W5KzlQpTltGmatm27rotNY4whBOJzg0Baqz2ImAYiEkKMAkpX2aIsi6Lw3td1HULw3kspERUqknhAa50GpRTnfCSVQqXyPJ/NZl3XhRBSqqGblLJpGiEEIvZ9zxg7dA5tRKWyLJtOp23brtdrrTUA7GITJTEiJkH646HzqXWadJ7nRVEopZxziMg5J6I0SCmrqjLGAIBSarVa1XVtrd3FNoyIKMuysiwRkTGWMg/hAcB7n7YCANKGXIvzIzafz7NsnDpba+UeRJR/CSEMM+yRUjLGRpPxjtQ5nfPtZfLeJ6uh+fWB5flkMpkkcdM0Mcaqqm47t23rvU/3lN7uY+8ZnEMIqXm6ksG5rmsiGp4PzgdxjDE5OOfS1wCAiCGEGKNzzu9J5n+cfwN03H8iMTWIRAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c23d4d79e250beafe0336d0b98fc8d7/e4706/ioBrokerv7_INSTAR_MQTTv5_03.avif 230w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/d1af7/ioBrokerv7_INSTAR_MQTTv5_03.avif 460w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/7f308/ioBrokerv7_INSTAR_MQTTv5_03.avif 920w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/5578a/ioBrokerv7_INSTAR_MQTTv5_03.avif 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c23d4d79e250beafe0336d0b98fc8d7/a0b58/ioBrokerv7_INSTAR_MQTTv5_03.webp 230w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/bc10c/ioBrokerv7_INSTAR_MQTTv5_03.webp 460w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/966d8/ioBrokerv7_INSTAR_MQTTv5_03.webp 920w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/7efa9/ioBrokerv7_INSTAR_MQTTv5_03.webp 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c23d4d79e250beafe0336d0b98fc8d7/81c8e/ioBrokerv7_INSTAR_MQTTv5_03.png 230w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/08a84/ioBrokerv7_INSTAR_MQTTv5_03.png 460w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/c0255/ioBrokerv7_INSTAR_MQTTv5_03.png 920w", "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/09e48/ioBrokerv7_INSTAR_MQTTv5_03.png 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c23d4d79e250beafe0336d0b98fc8d7/c0255/ioBrokerv7_INSTAR_MQTTv5_03.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The client will try to connect when you save your settings. In the following screenshot I connected the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` to verify that the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iobroker/state`}</code>{` topic appears:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b043f9ec79788cf1b5b38b826e23ed31/3dde1/ioBrokerv7_INSTAR_MQTTv5_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA/0lEQVQY013KyU7CQACA4b6NmLTTbQjdprN0gW5TzVRNMbQQTooXIvGlICq19flMJfFg8l3+5JdwdGNYVJ8R2XAV05MNTzFHAKIZiqduKBsugGOqEAE4Dr8PUqdYCu4+k2Wfr4Zk2UX352r7fbseeNPzps8eu2LVJXXn5EcnP1r8hMr3+OHMxIfYfm32g8TFgYtXB7cu2Vh+65DWwWsbt39cugnTl7J6q+d7sniKsl2Y7uLsWdQHSVQVL3IfWYx60ASKfAWUCQDXF4oy0XV5HmJRpkXEKHEZ9QLmYd/hRSIt8iZaVLZtG4apafo/qqqZEEaYBYj4lFLKLjAmRcF/AIBzVdYk4RFQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b043f9ec79788cf1b5b38b826e23ed31/e4706/ioBrokerv7_INSTAR_MQTTv5_04.avif 230w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/d1af7/ioBrokerv7_INSTAR_MQTTv5_04.avif 460w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/7f308/ioBrokerv7_INSTAR_MQTTv5_04.avif 920w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/7a54e/ioBrokerv7_INSTAR_MQTTv5_04.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b043f9ec79788cf1b5b38b826e23ed31/a0b58/ioBrokerv7_INSTAR_MQTTv5_04.webp 230w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/bc10c/ioBrokerv7_INSTAR_MQTTv5_04.webp 460w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/966d8/ioBrokerv7_INSTAR_MQTTv5_04.webp 920w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/5304f/ioBrokerv7_INSTAR_MQTTv5_04.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b043f9ec79788cf1b5b38b826e23ed31/81c8e/ioBrokerv7_INSTAR_MQTTv5_04.png 230w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/08a84/ioBrokerv7_INSTAR_MQTTv5_04.png 460w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/c0255/ioBrokerv7_INSTAR_MQTTv5_04.png 920w", "/en/static/b043f9ec79788cf1b5b38b826e23ed31/3dde1/ioBrokerv7_INSTAR_MQTTv5_04.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b043f9ec79788cf1b5b38b826e23ed31/c0255/ioBrokerv7_INSTAR_MQTTv5_04.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now I can go back to the ioBroker `}<strong parentName="p">{`MQTT-Client`}</strong>{` configuration and add an additional subscription. All my cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`are configured`}</a>{` to use the `}<strong parentName="p">{`MQTT Prefix`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras`}</code>{` followed by a `}<strong parentName="p">{`Camera ID`}</strong>{`. To subscribe to all my camera topics I need a wildcard subscription for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/#`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a8b72e8f340304c94d16b12ee327f4d/fbf08/ioBrokerv7_INSTAR_MQTTv5_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA60lEQVQY02MQAgNBQUF1dXUTExNVVVVFRUUF4gADXLOampq+vr6enp6mpqaioqISElBEAsjiUM1CQkJy8goKSsoKikqYSElZBYIUlZSR1cBsFhJSlBbTUpTWUZZRl5eQFeWXE4MhUT51OXEtRWlNRWklGTEZET4FsLi8GD9MswC/aUSJVUavaVK7aXK7VWqHSVKHaXI7GHWYJnWYp3Q4ZnQ4ZXbYprUbJLQbJbYbJXVBNAsL8vH4zLrut+2/8+q/zqv/BWz857z6n8va/65r/7usASHv9f8y9v3PPvA/Ydd/p9X/PNf+c9vwHwC12VI3KSBG8wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a8b72e8f340304c94d16b12ee327f4d/e4706/ioBrokerv7_INSTAR_MQTTv5_05.avif 230w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/d1af7/ioBrokerv7_INSTAR_MQTTv5_05.avif 460w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/7f308/ioBrokerv7_INSTAR_MQTTv5_05.avif 920w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/4346e/ioBrokerv7_INSTAR_MQTTv5_05.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a8b72e8f340304c94d16b12ee327f4d/a0b58/ioBrokerv7_INSTAR_MQTTv5_05.webp 230w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/bc10c/ioBrokerv7_INSTAR_MQTTv5_05.webp 460w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/966d8/ioBrokerv7_INSTAR_MQTTv5_05.webp 920w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/c76c7/ioBrokerv7_INSTAR_MQTTv5_05.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a8b72e8f340304c94d16b12ee327f4d/81c8e/ioBrokerv7_INSTAR_MQTTv5_05.png 230w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/08a84/ioBrokerv7_INSTAR_MQTTv5_05.png 460w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/c0255/ioBrokerv7_INSTAR_MQTTv5_05.png 920w", "/en/static/2a8b72e8f340304c94d16b12ee327f4d/fbf08/ioBrokerv7_INSTAR_MQTTv5_05.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a8b72e8f340304c94d16b12ee327f4d/c0255/ioBrokerv7_INSTAR_MQTTv5_05.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After saving the configuration I can now check the `}<strong parentName="p">{`Object Tree`}</strong>{` and see if the camera topics start arriving. `}<strong parentName="p">{`Note`}</strong>{` the topics you are seeing here are the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`retained`}</code>{` `}<strong parentName="p">{`Status Topics`}</strong>{` that reflect your current camera state - `}<em parentName="p">{`and not`}</em>{` the `}<strong parentName="p">{`Command Topics`}</strong>{` you need to `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#status-command-or-raw-topics"
      }}>{`change the state on your camera`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c7e4fbf8189856d240667b2e557f5ffc/1ff84/ioBrokerv7_INSTAR_MQTTv5_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACBklEQVQoz42Ny27TQBSGvUfIsWccfJuLPTfH49jjW5xUVUFC0BWgJpRdltBFXZYsQCx4g/JIPADvhNIQhCKV8unX6MyRvvNbHpGuY/tB0DRN33Wr1Wo5LBhj4oD8iz9LzrkQwhJZEYSh502lylRhirKiSld1I4RgjCmlKKX4AOdcqUxKqbVmjFkzPQf2I+hOhJRMKiEk5Wqm9f52nueMMc65lJIQwhjTWmdZtv9a88UZmRmfyrxsjakwwUO7On/+OorCNE2TA/uZ3sE5L8syDEPrze2PF99/dle3qcxNN0jJBZembBCKCSEY4/27HwghCKE0Tdu2DYLAaroTWS+jRGJM8qrLlIrjOIojSii5hyRJfjeXPA38KXAmGMVi3q9OnwnBDzX3yn3f72Qhle/70PMwipkqTNPNC43/aVNKjTE7WWUKAAChRzBKxKxZnpmqQugBuW3bnTzPNfQ8CCHBiDJZ1ENR6Afluq7vmoWCO3cvK7M4LecFQui/ZD8IgetCzyOEcN10q6dlVWGa0CS9L0nKun4RhpHlOBPHdSEAEXhMCS1mmaJhBO0Q2rF3nMi7208nWlB/Ci0AoDOxfZSUrz4st1/Or74N7z62m3F5eWPWx2k2N8PlLi/ff6XFwnIBcCb2k5iWF+PJ9vNwOTbr62Yzdpuxurg+Sr2+7jZj93Y8234iRf8LJ1KMFP8PNgEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7e4fbf8189856d240667b2e557f5ffc/e4706/ioBrokerv7_INSTAR_MQTTv5_06.avif 230w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/d1af7/ioBrokerv7_INSTAR_MQTTv5_06.avif 460w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/7f308/ioBrokerv7_INSTAR_MQTTv5_06.avif 920w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/781d2/ioBrokerv7_INSTAR_MQTTv5_06.avif 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c7e4fbf8189856d240667b2e557f5ffc/a0b58/ioBrokerv7_INSTAR_MQTTv5_06.webp 230w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/bc10c/ioBrokerv7_INSTAR_MQTTv5_06.webp 460w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/966d8/ioBrokerv7_INSTAR_MQTTv5_06.webp 920w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/02506/ioBrokerv7_INSTAR_MQTTv5_06.webp 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c7e4fbf8189856d240667b2e557f5ffc/81c8e/ioBrokerv7_INSTAR_MQTTv5_06.png 230w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/08a84/ioBrokerv7_INSTAR_MQTTv5_06.png 460w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/c0255/ioBrokerv7_INSTAR_MQTTv5_06.png 920w", "/en/static/c7e4fbf8189856d240667b2e557f5ffc/1ff84/ioBrokerv7_INSTAR_MQTTv5_06.png 1040w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c7e4fbf8189856d240667b2e557f5ffc/c0255/ioBrokerv7_INSTAR_MQTTv5_06.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now use ioBroker to take control of our camera. Every topic listed can be updated - either manually through the ioBroker interface or by an automation script. As an example I will use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Multimedia/Privacy_Mask/"
      }}>{`Privacy Mask`}</a>{` as an example:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/cd138/ioBrokerv7_INSTAR_MQTTv5_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAsElEQVQI13XOWQqCQAAAUK+ROjpLpo6p0yhqi1mGYYeoEPzIIDxCiAVJy5k7gAXvAE/AZOR5PkGqIktQAUASIQAEQUWWfiIIYqQaumZRU4iKdle/Z+UtKNrV6cH313l5z+uPf2z6vEOTXV7J+bmuurTqBJ2F0zQ3WKjZvhPEyGSUR2GSEYv3YTrxFxsniN1wyeOtgKHKmasRDIFs6iNZHAwxYo79r21b1DINSo2x7XwB2j0x07PGuHMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c1f1718bad6eda5d382b121a6ec5f65/e4706/ioBrokerv7_INSTAR_MQTTv5_07.avif 230w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/d1af7/ioBrokerv7_INSTAR_MQTTv5_07.avif 460w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/7f308/ioBrokerv7_INSTAR_MQTTv5_07.avif 920w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/bffb7/ioBrokerv7_INSTAR_MQTTv5_07.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c1f1718bad6eda5d382b121a6ec5f65/a0b58/ioBrokerv7_INSTAR_MQTTv5_07.webp 230w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/bc10c/ioBrokerv7_INSTAR_MQTTv5_07.webp 460w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/966d8/ioBrokerv7_INSTAR_MQTTv5_07.webp 920w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/ea5cd/ioBrokerv7_INSTAR_MQTTv5_07.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c1f1718bad6eda5d382b121a6ec5f65/81c8e/ioBrokerv7_INSTAR_MQTTv5_07.png 230w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/08a84/ioBrokerv7_INSTAR_MQTTv5_07.png 460w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/c0255/ioBrokerv7_INSTAR_MQTTv5_07.png 920w", "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/cd138/ioBrokerv7_INSTAR_MQTTv5_07.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c1f1718bad6eda5d382b121a6ec5f65/c0255/ioBrokerv7_INSTAR_MQTTv5_07.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`But as mentioned above we cannot update the `}<strong parentName="p">{`Status Topic`}</strong>{`. We need the `}<strong parentName="p">{`Command Topic`}</strong>{` to tell our camera to turn those masks on or off. Again I am going to use the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` - this time to send these commands to my camera and have them show up in the ioBroker Object Tree.`}</p>
    <p>{`The camera I am using has the prefix `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras`}</code>{` and camera ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`118`}</code>{` - the status and command topic for the privacy mask 1 is accordingly:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Status Topic`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/status/multimedia/privacy/region4/enable`}</code></li>
      <li parentName="ul"><strong parentName="li">{`Command Topic`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region4/enable`}</code></li>
      <li parentName="ul"><strong parentName="li">{`RAW Command Topic`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region4/enable/raw`}</code></li>
    </ul>
    <p><em parentName="p">{`NOTE: the RAW topic allows us to use string values for the command instead of the regular `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`JSON`}</code>{` expression - so it is only `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`0`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`1`}</code>{` instead of `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`{"val":"0"}`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba207d19938a9a52f110145a037af883/b12f7/ioBrokerv7_INSTAR_MQTTv5_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABeklEQVQoz3WNwW7TMACG/TaDlsSO7Wl10sSxEye209ROulGpLbCGiYlJO3DiwJ1XooIVOp4MbZNgMPHpO/yXTz9Qy292s5+/+WE3e726WV7+PL247YdDPxy67aEbDrNX+9R94d2Od7u83+nVTXH29eXl93cfb0Hbf7D++iRdJflmkq4ZX7NsPUn/GPNNKoep3E7F+VSeczVkxVbWb0szAJ4x7+zkJMLR+JhCGD4Lg6PfBi+OIjQyWlaKizyWIhF5fG8ixRRAiBaLU6UqSo8JIQhFj4UQEUKrqlaqyjLOef5gmmZNMwNBEGhtlFIIoSiK0N9ACDHG/J70EUmSWGtBGIbW1EUhgiBAT4AQEkKstVprIYSUsrijlLJwzoPR6Pls/ro2Z+PxCEL4v9h7753rvLPWzlttjfSuBUqVxs5zUWZZxhj7p3+IjTFt286a1jbu6up9t/zULD77/gJUVVWWBWMTSinG+OkzpTSOGcZ3OwijJGGI8DGSqm5/AcLWjgaFrLRuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba207d19938a9a52f110145a037af883/e4706/ioBrokerv7_INSTAR_MQTTv5_09.avif 230w", "/en/static/ba207d19938a9a52f110145a037af883/d1af7/ioBrokerv7_INSTAR_MQTTv5_09.avif 460w", "/en/static/ba207d19938a9a52f110145a037af883/7f308/ioBrokerv7_INSTAR_MQTTv5_09.avif 920w", "/en/static/ba207d19938a9a52f110145a037af883/2c8af/ioBrokerv7_INSTAR_MQTTv5_09.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba207d19938a9a52f110145a037af883/a0b58/ioBrokerv7_INSTAR_MQTTv5_09.webp 230w", "/en/static/ba207d19938a9a52f110145a037af883/bc10c/ioBrokerv7_INSTAR_MQTTv5_09.webp 460w", "/en/static/ba207d19938a9a52f110145a037af883/966d8/ioBrokerv7_INSTAR_MQTTv5_09.webp 920w", "/en/static/ba207d19938a9a52f110145a037af883/52c2b/ioBrokerv7_INSTAR_MQTTv5_09.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba207d19938a9a52f110145a037af883/81c8e/ioBrokerv7_INSTAR_MQTTv5_09.png 230w", "/en/static/ba207d19938a9a52f110145a037af883/08a84/ioBrokerv7_INSTAR_MQTTv5_09.png 460w", "/en/static/ba207d19938a9a52f110145a037af883/c0255/ioBrokerv7_INSTAR_MQTTv5_09.png 920w", "/en/static/ba207d19938a9a52f110145a037af883/b12f7/ioBrokerv7_INSTAR_MQTTv5_09.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba207d19938a9a52f110145a037af883/c0255/ioBrokerv7_INSTAR_MQTTv5_09.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In addition I want to have a single variable that I can toggle that will then toggle all areas at once. I will use the topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/privacy`}</code>{` that will expect either an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` to toggle all privacy masks at once:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/51ed8/ioBrokerv7_INSTAR_MQTTv5_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABzklEQVQoz32OSW7bMABFeZwMFql5pGRKlERSIkXGtVtbbhAjSFC02XbXI/Q0TQc7yeUKJ0BgNEYf3vbhf1CwVYCVn0kYMBTyvdFeO+JRof1MWX6LQvYqDPaiiLtxB+rFr369G6528uOWLf8sbp/eXT+azYPZPKjLrb7aycsdHn4U5r4w99OLn2Lc1u9/rz4/3nx9AtLcdOraj2QQKz+WYaKCQ2MVpZr3Gz37VNYr2oy0XVfNWLN1zUeAs3Ax1zgLPdeKQttGpxCeIHj6IrROXPu865tOsqouSpqTKi9p3isuRA0siIZBcy6CIHQ937adQxGyXc9XTWuqimcZzzKWpn1RjEqNH5bAsizGWF3XlmXZb0AIeZ5fsYSZpDUpm8XMRGRwcHs6v1AAQiiE+G/stZyxXpNaEFoRWhdVWRCitQbnZ2ectU3TTCaTo7HrulVZkukUZ2mOcY5xkec4y6SUQMjlMLvNn4njGCH0T+z7vhCibdvqAEKIMQZ0neRCxXEcBIHneUdvd12ntRaCU7ovaVVSSjhnACHo2MhxHPTM2+eu63IulFJ9L7vO3N19UfNv/eI7oQvwumAf4+V2miae50BoWRCGkW+7CXJwkhZ/AWdXtO7bDFwUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/e4706/ioBrokerv7_INSTAR_MQTTv5_10.avif 230w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/d1af7/ioBrokerv7_INSTAR_MQTTv5_10.avif 460w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/7f308/ioBrokerv7_INSTAR_MQTTv5_10.avif 920w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/14e47/ioBrokerv7_INSTAR_MQTTv5_10.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/a0b58/ioBrokerv7_INSTAR_MQTTv5_10.webp 230w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/bc10c/ioBrokerv7_INSTAR_MQTTv5_10.webp 460w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/966d8/ioBrokerv7_INSTAR_MQTTv5_10.webp 920w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/11b7f/ioBrokerv7_INSTAR_MQTTv5_10.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/81c8e/ioBrokerv7_INSTAR_MQTTv5_10.png 230w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/08a84/ioBrokerv7_INSTAR_MQTTv5_10.png 460w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/c0255/ioBrokerv7_INSTAR_MQTTv5_10.png 920w", "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/51ed8/ioBrokerv7_INSTAR_MQTTv5_10.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d2963d05c83b47f5e4c4c9fc425eb49e/c0255/ioBrokerv7_INSTAR_MQTTv5_10.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that ioBroker noticed the new topics:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b3ffe68f80405a0590c43fcef092c16e/78612/ioBrokerv7_INSTAR_MQTTv5_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABd0lEQVQoz42O30obQRSH5wmilBQq6Z4zM2dmJ5ndTRpQSW+CYSU7uwkJFvGiN3b9k0CklwW92FJFr7wQBB+gb1riogSsZD++GX5zhjNnmHNJkgydc2malnuWZXEc93q9r+/wesV0Kwg6Xdv+om2ktVJquZRSRKTLs9ZEpJTyfX81+L7PBPkkiXMOgEIIRIQXhBCccyklACBiGTjnROR5npSScdKmZaUU8NyGb3hbLCtExABRG6uUKl/FyiwnA6JpWWMMAEgpq7SVM4wxDABt1A2CwPM8Y4wQ4r+fX8XXWhJZaxkK2QzazZZFLiQpLmQVkQsbhKxR39AcNGw16jX49OHzx40KbjbqtabiLMxOdr4tdg/mYZZ3xifRaL3tLA9Hp9vDQ9Y9WGxPZ53sR5Tm7SyP0jWG7jh0x0GadwZTNr24Hs2L4VmRnBejWeHOl+FdZ7/d4na8uJn8vIuP5qwfD/txUkHXH8R7k+/7l39/Xd8/PD5dFX/+AddUluuM1cvMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3ffe68f80405a0590c43fcef092c16e/e4706/ioBrokerv7_INSTAR_MQTTv5_12.avif 230w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/d1af7/ioBrokerv7_INSTAR_MQTTv5_12.avif 460w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/7f308/ioBrokerv7_INSTAR_MQTTv5_12.avif 920w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/51f3d/ioBrokerv7_INSTAR_MQTTv5_12.avif 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b3ffe68f80405a0590c43fcef092c16e/a0b58/ioBrokerv7_INSTAR_MQTTv5_12.webp 230w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/bc10c/ioBrokerv7_INSTAR_MQTTv5_12.webp 460w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/966d8/ioBrokerv7_INSTAR_MQTTv5_12.webp 920w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/4ef06/ioBrokerv7_INSTAR_MQTTv5_12.webp 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3ffe68f80405a0590c43fcef092c16e/81c8e/ioBrokerv7_INSTAR_MQTTv5_12.png 230w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/08a84/ioBrokerv7_INSTAR_MQTTv5_12.png 460w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/c0255/ioBrokerv7_INSTAR_MQTTv5_12.png 920w", "/en/static/b3ffe68f80405a0590c43fcef092c16e/78612/ioBrokerv7_INSTAR_MQTTv5_12.png 1260w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b3ffe68f80405a0590c43fcef092c16e/c0255/ioBrokerv7_INSTAR_MQTTv5_12.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And make sure that ioBroker has both the subscription as well as the right to update the topic. This setting can be found under the `}<em parentName="p">{`cog wheel`}</em>{` icon on the far right of the list entry:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c71df4a8aa3a43f326dd7709505470bc/cf899/ioBrokerv7_INSTAR_MQTTv5_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABcUlEQVQoz2WOTW/aQBCGfeslqogbbLyfs7Mf9u4ajBENhkbKBUqgOVRKq0bNgaJe6Ll/oervrhoklNJHj17NSDOaSYzG9OIVp7T0tTKu9NEYY62VUiIiABxTPQMAWmtE9N4755LFpx/V8qssRyGO6rqWUh6HlFKIeMqXMM5CCBp18v7wu/v+Sw2vEXXTThWAEEI+cyz+bznnVVUhYhKB0Swlg0xpO5lea0RtzPG9EMJp8wSiBpDjcauNTao4zPIBIRRt2U7/3jfWAihtbIi1kCBBvVRroxQ27cRYl0Trrvr9Iu+DEDEExYmgBSe55ERJJujgXDJgReadVVIkCKZ/lUrfxtuPi/uneLOJN9tqvo7vNs3tfdmtq/k/+vnazVaz9efQLZNemvZeX+i3y9njz+7hUG+++dVTvNs1H/bNdjfc7s8cbffxbtc9HEarL8mbNL287FFKJ+1E0BwYWcympQbJCu8MJ/mZkhWsyMbDqJX8A9uwVP2X0o+wAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c71df4a8aa3a43f326dd7709505470bc/e4706/ioBrokerv7_INSTAR_MQTTv5_13.avif 230w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/d1af7/ioBrokerv7_INSTAR_MQTTv5_13.avif 460w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/7f308/ioBrokerv7_INSTAR_MQTTv5_13.avif 920w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/e1c99/ioBrokerv7_INSTAR_MQTTv5_13.avif 1380w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/9197a/ioBrokerv7_INSTAR_MQTTv5_13.avif 1513w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c71df4a8aa3a43f326dd7709505470bc/a0b58/ioBrokerv7_INSTAR_MQTTv5_13.webp 230w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/bc10c/ioBrokerv7_INSTAR_MQTTv5_13.webp 460w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/966d8/ioBrokerv7_INSTAR_MQTTv5_13.webp 920w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/445df/ioBrokerv7_INSTAR_MQTTv5_13.webp 1380w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/45b69/ioBrokerv7_INSTAR_MQTTv5_13.webp 1513w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c71df4a8aa3a43f326dd7709505470bc/81c8e/ioBrokerv7_INSTAR_MQTTv5_13.png 230w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/08a84/ioBrokerv7_INSTAR_MQTTv5_13.png 460w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/c0255/ioBrokerv7_INSTAR_MQTTv5_13.png 920w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/b1001/ioBrokerv7_INSTAR_MQTTv5_13.png 1380w", "/en/static/c71df4a8aa3a43f326dd7709505470bc/cf899/ioBrokerv7_INSTAR_MQTTv5_13.png 1513w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c71df4a8aa3a43f326dd7709505470bc/c0255/ioBrokerv7_INSTAR_MQTTv5_13.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here activate both the `}<strong parentName="p">{`Subscription`}</strong>{` as well as the `}<strong parentName="p">{`Publish`}</strong>{` option:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/28ddff8c632f8df241722d721f6c4be9/add4c/ioBrokerv7_INSTAR_MQTTv5_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABP0lEQVQY023LzVKCUACGYW4mAYWDgvwcRMrgnCOhoiZBBDVNtXGSwHHRtlYtmqw2XUDTtk0LaaaLa8Z+bfrmWXyblyL+obN36kRjJ06dJN1KUieeuPuZm5w5cdo5yFA4NgdH68PjFdsnZi+h8PkDunpuXTzi6xdyV+CbxZdiaUHmRfv2dcW8aN+/4csnCnXaaIRbXtN0VcNTIBE1JKqoJlvCEvhkizKSfmBJRQrFCzWe55WmDclQtTzN9iDqSxsuaGDBwIJBgEFAA5crgKUZlmG/cRWOEoBAl9bqJIDRTA1yPZrK/kSPpno0hbv5x4dhVhbqLEOzv8ZxHAWAwNAlEY0UP9WCHAaZupPCMIdh1oxnqj+BYa75aaX6fwwYmq7qm1LLVTa7stWrmY5id2XLMzqRggaS1Vdsr8wBlmH+xO/RpHkLpRiEyQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/28ddff8c632f8df241722d721f6c4be9/e4706/ioBrokerv7_INSTAR_MQTTv5_14.avif 230w", "/en/static/28ddff8c632f8df241722d721f6c4be9/d1af7/ioBrokerv7_INSTAR_MQTTv5_14.avif 460w", "/en/static/28ddff8c632f8df241722d721f6c4be9/7f308/ioBrokerv7_INSTAR_MQTTv5_14.avif 920w", "/en/static/28ddff8c632f8df241722d721f6c4be9/e1c99/ioBrokerv7_INSTAR_MQTTv5_14.avif 1380w", "/en/static/28ddff8c632f8df241722d721f6c4be9/950ab/ioBrokerv7_INSTAR_MQTTv5_14.avif 1452w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/28ddff8c632f8df241722d721f6c4be9/a0b58/ioBrokerv7_INSTAR_MQTTv5_14.webp 230w", "/en/static/28ddff8c632f8df241722d721f6c4be9/bc10c/ioBrokerv7_INSTAR_MQTTv5_14.webp 460w", "/en/static/28ddff8c632f8df241722d721f6c4be9/966d8/ioBrokerv7_INSTAR_MQTTv5_14.webp 920w", "/en/static/28ddff8c632f8df241722d721f6c4be9/445df/ioBrokerv7_INSTAR_MQTTv5_14.webp 1380w", "/en/static/28ddff8c632f8df241722d721f6c4be9/78e5d/ioBrokerv7_INSTAR_MQTTv5_14.webp 1452w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/28ddff8c632f8df241722d721f6c4be9/81c8e/ioBrokerv7_INSTAR_MQTTv5_14.png 230w", "/en/static/28ddff8c632f8df241722d721f6c4be9/08a84/ioBrokerv7_INSTAR_MQTTv5_14.png 460w", "/en/static/28ddff8c632f8df241722d721f6c4be9/c0255/ioBrokerv7_INSTAR_MQTTv5_14.png 920w", "/en/static/28ddff8c632f8df241722d721f6c4be9/b1001/ioBrokerv7_INSTAR_MQTTv5_14.png 1380w", "/en/static/28ddff8c632f8df241722d721f6c4be9/add4c/ioBrokerv7_INSTAR_MQTTv5_14.png 1452w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/28ddff8c632f8df241722d721f6c4be9/c0255/ioBrokerv7_INSTAR_MQTTv5_14.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now jump over to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Skripte`}</code>{` adapter - you might have to install it from the `}<strong parentName="p">{`Adapter`}</strong>{` menu first. and select the `}<em parentName="p">{`if-then-else`}</em>{` `}<strong parentName="p">{`Rules`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/76a99/ioBrokerv7_INSTAR_MQTTv5_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACVklEQVQoz22SzWvTYBjAA65d0+bjTZNu3bokb5q8eZs2zXfamX6s0HYp2/ATQRSGU9mmDJGpB9n0MNxBvCiCf4CIHnZ3IHjx5GUIXvZPePYkTLo6UdiPHw/Px+F5Dg+BcGkil2MBR1FpgedYhk6TqUyapDIkl8uDLM/QaYahToVQNJ3nwKSoClKZnlSoCZjJyWlBBtMaK0wDwACGZOj0qRAKhIIgsIBLkPRYihpL0UNJJkUBMkMnEolEcjyZHB+R/B9CVdV8Pk/TtCxJGCOsq+US0rWiIhcUKIuiOCOKf2NRkY6ViwpUoEwghERRZBim2WouLCx2uout7lLUGTjhXD3qDOJ4MIjjfncw3+v1ukF97o/hbP1se7h5eDbLBr5Xm22embnE2qtCsMZa66p/dfvxo63dl1uvPmy/3ru98eDTm/LBe/PrO//b/v23L/qEJMMsLwAAwjCIGu3UzPyFlSfX7+ys3HveW1q5dfPG6sbm2ubT9Yc715aXf3zBR9+VX4eXj37uHezfJQqTPMgkGToTBGGjEUFZkiXZtqypfB4hBCFEmhp4TmFqqoRR3NbO9fGVi/3FuN3t1Ijy3HkY9oUC9D03ihoIIdM0a2GIENJ1Het61aw2my2EEMalglgsGXYY1iUJQlgk+s8+tnc/S2HsWZUoauCyaVpO1XaMqq0hXdPxccdWVKRjXDErtuO4nl+xHGwYBMdx2WwWAFCr1VqtluV6pu2Zlms6vucHthcMS9szHd8PQtsLKv9MCV4Q+OGTAMMwnCG26zgjR5zk9kjXddyT2W/v0YCg2TM2FAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/e4706/ioBrokerv7_INSTAR_MQTTv5_08.avif 230w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/d1af7/ioBrokerv7_INSTAR_MQTTv5_08.avif 460w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/7f308/ioBrokerv7_INSTAR_MQTTv5_08.avif 920w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/88d8d/ioBrokerv7_INSTAR_MQTTv5_08.avif 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/a0b58/ioBrokerv7_INSTAR_MQTTv5_08.webp 230w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/bc10c/ioBrokerv7_INSTAR_MQTTv5_08.webp 460w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/966d8/ioBrokerv7_INSTAR_MQTTv5_08.webp 920w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/faa4b/ioBrokerv7_INSTAR_MQTTv5_08.webp 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/81c8e/ioBrokerv7_INSTAR_MQTTv5_08.png 230w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/08a84/ioBrokerv7_INSTAR_MQTTv5_08.png 460w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/c0255/ioBrokerv7_INSTAR_MQTTv5_08.png 920w", "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/76a99/ioBrokerv7_INSTAR_MQTTv5_08.png 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de4e3bbdd4ecb31cb6ef579d7ce2e029/c0255/ioBrokerv7_INSTAR_MQTTv5_08.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we now have to drag a `}<strong parentName="p">{`State`}</strong>{` (`}<em parentName="p">{`Zustand`}</em>{`) block into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{` column on the left. Here click on the button with 3 dots and select the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/privacy`}</code>{` toggle from the `}<strong parentName="p">{`MQTT-Client`}</strong>{` objects:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/36bab16f7ac050d6b0836eef05574862/eb3fa/ioBrokerv7_INSTAR_MQTTv5_11a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABoElEQVQoz3WRu27UUBRFDWN7AuNr36d9H7bv22M7DIxgEqJQIEERQSL+gYYCCjp+gJaPRk5ANGZraWufYlUnIZTqrpunsa4phFXNmOT1fH0nwpGbyFSPEcJLHhqj5fyzk1dv3nd93whVc0lrzhrOGtFpZ52vG44QhhBijAmh900YY4QQjBeS/vBWX9zq0wd7cdufbsLVnXv9qVa23D0BBcizjDfCh1F1xrjo4xiGSbWm7a22IYEIQUQgwhBhhAgmBGGilOKcl6DcPHrMWOPj2Gqv3dDb2Pau074zPu7PE7wSRClFEMEKnm3PdqCSnWu1F50VnZX38NbYsF+VsaCMQYRBucu3pEKtja2NUvsF46UJovcmjuuyorRBCAPwNN3QCjbat26QJizYKG0UJug4rciE0qqqsjTd5nmepkKqZy8vpdtL/w/hBjs/X5OXVxBQFCUosjS9PJ2+fP3W2EHFScZRLj2JMNrzF+syxhgUBShhukmP1+8+//jJbZDDLIfpARHHfj78V94Vxa4ieZq5q483339J6+T+r7yfxTDZw/E3JFFDblhbtbwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36bab16f7ac050d6b0836eef05574862/e4706/ioBrokerv7_INSTAR_MQTTv5_11a.avif 230w", "/en/static/36bab16f7ac050d6b0836eef05574862/d1af7/ioBrokerv7_INSTAR_MQTTv5_11a.avif 460w", "/en/static/36bab16f7ac050d6b0836eef05574862/7f308/ioBrokerv7_INSTAR_MQTTv5_11a.avif 920w", "/en/static/36bab16f7ac050d6b0836eef05574862/b248f/ioBrokerv7_INSTAR_MQTTv5_11a.avif 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/36bab16f7ac050d6b0836eef05574862/a0b58/ioBrokerv7_INSTAR_MQTTv5_11a.webp 230w", "/en/static/36bab16f7ac050d6b0836eef05574862/bc10c/ioBrokerv7_INSTAR_MQTTv5_11a.webp 460w", "/en/static/36bab16f7ac050d6b0836eef05574862/966d8/ioBrokerv7_INSTAR_MQTTv5_11a.webp 920w", "/en/static/36bab16f7ac050d6b0836eef05574862/1dc52/ioBrokerv7_INSTAR_MQTTv5_11a.webp 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/36bab16f7ac050d6b0836eef05574862/81c8e/ioBrokerv7_INSTAR_MQTTv5_11a.png 230w", "/en/static/36bab16f7ac050d6b0836eef05574862/08a84/ioBrokerv7_INSTAR_MQTTv5_11a.png 460w", "/en/static/36bab16f7ac050d6b0836eef05574862/c0255/ioBrokerv7_INSTAR_MQTTv5_11a.png 920w", "/en/static/36bab16f7ac050d6b0836eef05574862/eb3fa/ioBrokerv7_INSTAR_MQTTv5_11a.png 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/36bab16f7ac050d6b0836eef05574862/c0255/ioBrokerv7_INSTAR_MQTTv5_11a.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{` block on the right we need to place 4 `}<strong parentName="p">{`Set State`}</strong>{` (`}<em parentName="p">{`Zustand setzen`}</em>{`) blocks and add the 4 `}<strong parentName="p">{`RAW Command Topics`}</strong>{` for the 4 privacy masks:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region1/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region2/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region3/enable/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/multimedia/privacy/region4/enable/raw`}</code></li>
    </ul>
    <p>{`Make sure the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`use trigger value`}</code>{` option is checked. This will forward the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` received by the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/privacy`}</code>{` topic and automatically turn each mask on or off:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87b6c7a5184075381a59c8bdf1ade84e/f79fa/ioBrokerv7_INSTAR_MQTTv5_11b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABVklEQVQY033HSU/CQABA4R5EgZbOdDo6nc7WdtoCTQVORqMmimuiJ+PJxERFwBVBFFz+vFET9eTLd3nGvEiJ1ERoF2PXRe6/AQAqFRs5wLSgVXGMpL5IRYA9H7nYgdD5E/xa9A0hCGGWZcvLSwuytd+OttYCAyMH2hWrXLZM06PMZ5L6wqOcUB4nNcYV8RjxGPUlwiTSaZ43pG6uLKWtRmyYMwVYNrFtew5qLjZDXVVhktbyuJrFaabCRCitwqSet9rbexvtndX1zYPDw6Pjk63dAwPZYB46xMWgWOIe82UklOafIiZDLiMRxJQHaT1/eXt/ep5e3971r25Gj+O7wdAgLkY2sIql0kyBUO4rzYPYF+EPJiPCVDVrjMaTwfDxfjC8fxg+T19H44kBADTL5txcsVCYXaCCBTEPE1/pX4H2ZKRr+WX/5qJ7ddbpnXV6nW7/9LzzAbOGPyQE4IOyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87b6c7a5184075381a59c8bdf1ade84e/e4706/ioBrokerv7_INSTAR_MQTTv5_11b.avif 230w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/d1af7/ioBrokerv7_INSTAR_MQTTv5_11b.avif 460w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/7f308/ioBrokerv7_INSTAR_MQTTv5_11b.avif 920w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/f897f/ioBrokerv7_INSTAR_MQTTv5_11b.avif 940w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87b6c7a5184075381a59c8bdf1ade84e/a0b58/ioBrokerv7_INSTAR_MQTTv5_11b.webp 230w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/bc10c/ioBrokerv7_INSTAR_MQTTv5_11b.webp 460w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/966d8/ioBrokerv7_INSTAR_MQTTv5_11b.webp 920w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/e8e7c/ioBrokerv7_INSTAR_MQTTv5_11b.webp 940w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87b6c7a5184075381a59c8bdf1ade84e/81c8e/ioBrokerv7_INSTAR_MQTTv5_11b.png 230w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/08a84/ioBrokerv7_INSTAR_MQTTv5_11b.png 460w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/c0255/ioBrokerv7_INSTAR_MQTTv5_11b.png 920w", "/en/static/87b6c7a5184075381a59c8bdf1ade84e/f79fa/ioBrokerv7_INSTAR_MQTTv5_11b.png 940w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87b6c7a5184075381a59c8bdf1ade84e/c0255/ioBrokerv7_INSTAR_MQTTv5_11b.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The complete `}<em parentName="p">{`Script`}</em>{` now looks like:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/a4f81/ioBrokerv7_INSTAR_MQTTv5_11c.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACK0lEQVQoz2XPWVPaUAAF4LxXs9ybhOTeLDd7QgJNRKy1UqkLiihSpw/aMpYKiFVAiCAunf6MPva3drB96LTfnNczZw6FjEDgeV3XkjjOF/JxYdl1HC8bIc1BiiXLSJIkQojxzHxmEKLrBiEGFcWFTEbExFJ1IsmIYTleEBQ7d7SunJWY+v7W++MPGGOGYQAADMOIoiBj3TRlXeUpx3V5HmAj0HIbKHhN8iUclgwv3lkxK0X8dn19c3vPcQNi2Eghqm5GuTgprO1sl/O5kAp8nxDCsiyAEPI8hDwAUNUNHju0aLKCDDMIa5ZqOEgzvDA/SifD4c1ker+7X6dUVZMkGfyF4zik6orpGo5v+aFq+YjYiNiSZpp+9KnVbnUu+sPRu8oeFYZZUeABxwEIf4/TNP3qTelzp9dsnV9cD7x4OWM42Akk0zWjuPmlc9I8615elSv7VBQmkm5DQZz3wby+uLBQKm/Onr5PH54mj9+yxdWM5WIvlGzPfrnU7Q97/eFocrdTa1ClwY+19CcOV9nFF2B+GHAsq5tukKwES0U/KerZSPECJcgi17fzyWmrfXp23rsebGzvUiz4HydhleZlmpcWRQSwxiKNQzotKdjxvw5uLq/6o3Ra3tqlAOCe8weEkGUZ03Iq1VqlWts7qFcPj6qHjVrjuFpvHDSOW+1uu9vrXFyefGxS/4xCCGmaTpLkNp27vZ1nOpncz2b3s9nddJqOx2majsfjx4eHX1NZfQ0tJg+fAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/e4706/ioBrokerv7_INSTAR_MQTTv5_11c.avif 230w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/d1af7/ioBrokerv7_INSTAR_MQTTv5_11c.avif 460w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/7f308/ioBrokerv7_INSTAR_MQTTv5_11c.avif 920w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/e1c99/ioBrokerv7_INSTAR_MQTTv5_11c.avif 1380w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/0c1da/ioBrokerv7_INSTAR_MQTTv5_11c.avif 1508w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/a0b58/ioBrokerv7_INSTAR_MQTTv5_11c.webp 230w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/bc10c/ioBrokerv7_INSTAR_MQTTv5_11c.webp 460w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/966d8/ioBrokerv7_INSTAR_MQTTv5_11c.webp 920w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/445df/ioBrokerv7_INSTAR_MQTTv5_11c.webp 1380w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/57961/ioBrokerv7_INSTAR_MQTTv5_11c.webp 1508w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/81c8e/ioBrokerv7_INSTAR_MQTTv5_11c.png 230w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/08a84/ioBrokerv7_INSTAR_MQTTv5_11c.png 460w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/c0255/ioBrokerv7_INSTAR_MQTTv5_11c.png 920w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/b1001/ioBrokerv7_INSTAR_MQTTv5_11c.png 1380w", "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/a4f81/ioBrokerv7_INSTAR_MQTTv5_11c.png 1508w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c8cdaef5ef33fbd2e0075bf83ceefae/c0255/ioBrokerv7_INSTAR_MQTTv5_11c.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am now using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` to update the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/privacy`}</code>{` topic. Sending the value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` triggers the script and the value is forwarded to each RAW Command Topic turning all privacy areas on at once:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/294aeeaac2dae8043d31e68af28d1db5/e53e8/ioBrokerv7_INSTAR_MQTTv5_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABeElEQVQY01XNa0/aUACA4f6GJXB62gOF0krBci4FaU0tp6cXEIo0zjtqDLHeLzEm/gI/qVu2ZMl+74wuS/Z8f/NKk51f8ewtmr4MN9+S/DXJX8XG9zj/KTa+zQ5+rwR7ZUWRZVj+AAAolUoAAKhApJYlEQac+5TYuLOMO8u23Y6jYDTknHvZJOm7noJqMkQyRKhaV5GmIo2w3kAIz2WS6/bDMMQYU8ooZYQwEQXrYx4n3ngS9Lo9oGhI05FmVGoGrNRNyz6/vL69u9/anUsDLoSIGKOfCCGch3GcBgOexJFN2BdYhZqu1Ay1bsrVhm7ZRyeL4uJy9nVbipPMW/Udh/2L0zSdTqfjcZaONpNRlu/O54sCuz6om7LebHTo2c3d7cPj9vxYIgSTD3/XlHmrbrfLfH+t20+H69nB4/PW0w8cpEA34FJLJ85xcV5c3eQ7+xL9D3GY4zjEXKo2jIplac1WG9SastFWzJZqWrLR1DE9XJwWV9fv8R/+7F2MAht0YQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/294aeeaac2dae8043d31e68af28d1db5/e4706/ioBrokerv7_INSTAR_MQTTv5_15.avif 230w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/d1af7/ioBrokerv7_INSTAR_MQTTv5_15.avif 460w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/7f308/ioBrokerv7_INSTAR_MQTTv5_15.avif 920w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/e1c99/ioBrokerv7_INSTAR_MQTTv5_15.avif 1380w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/76ea5/ioBrokerv7_INSTAR_MQTTv5_15.avif 1840w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/e9f5d/ioBrokerv7_INSTAR_MQTTv5_15.avif 1982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/294aeeaac2dae8043d31e68af28d1db5/a0b58/ioBrokerv7_INSTAR_MQTTv5_15.webp 230w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/bc10c/ioBrokerv7_INSTAR_MQTTv5_15.webp 460w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/966d8/ioBrokerv7_INSTAR_MQTTv5_15.webp 920w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/445df/ioBrokerv7_INSTAR_MQTTv5_15.webp 1380w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/78de1/ioBrokerv7_INSTAR_MQTTv5_15.webp 1840w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/79c50/ioBrokerv7_INSTAR_MQTTv5_15.webp 1982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/294aeeaac2dae8043d31e68af28d1db5/81c8e/ioBrokerv7_INSTAR_MQTTv5_15.png 230w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/08a84/ioBrokerv7_INSTAR_MQTTv5_15.png 460w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/c0255/ioBrokerv7_INSTAR_MQTTv5_15.png 920w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/b1001/ioBrokerv7_INSTAR_MQTTv5_15.png 1380w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/161ec/ioBrokerv7_INSTAR_MQTTv5_15.png 1840w", "/en/static/294aeeaac2dae8043d31e68af28d1db5/e53e8/ioBrokerv7_INSTAR_MQTTv5_15.png 1982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/294aeeaac2dae8043d31e68af28d1db5/c0255/ioBrokerv7_INSTAR_MQTTv5_15.png",
              "alt": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "title": "ioBroker with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now all you need to do is the use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/privacy`}</code>{` topic in an automation script you are already using or add it to your ioBroker Vis in form of a button.`}</p>
    <p>{`And of course the privacy mask topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`multimedia/privacy/region1/enable`}</code>{` can be replaced with any of the many MQTT topics from the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTTv5 API`}</a>{`. Turning on the alarm, moving alarm areas or pan your Pan&Tilt camera to another position. All done through MQTT!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      